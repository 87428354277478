import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Layout from 'components/Layout';
import { Container, Buttons, Button } from 'ui';
import ArticleCard from 'components/ArticleCard';
import { GATSBY_PRISMIC_NAME, GATSBY_PRISMIC_TOKEN } from 'constants';
import { MdChevronLeft } from 'react-icons/md';
import Seo from 'components/SEO';
import * as prismic from '@prismicio/client';
import Header from 'components/Header';
import articles from 'images/articles.png';

const apiEndpoint = `https://${GATSBY_PRISMIC_NAME}.prismic.io/api/v2`;

const client = prismic.createClient(apiEndpoint, {
  accessToken: GATSBY_PRISMIC_TOKEN,
});

const ActualitiesList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [actualities, setActualities] = useState([]);

  const handleFetchData = async () => {
    try {
      const { results, page, total_pages } = await client.get({
        predicates: [prismic.predicate.at('my.actuality.target', 'public')],
        orderings: {
          field: 'document.first_publication_date',
          direction: 'desc',
        },
        pageSize: 5,
        page: currentPage,
      });
      setActualities(results);
      setCurrentPage(page);
      setTotalPage(total_pages);
    } catch (err) {}
  };

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <Layout>
      <Seo
        title="Handisoins"
        description="Accompagner la santé des personnes en situation de handicap"
      />
      <Header title="Articles" icon={articles} />
      <Container>
        <Buttons style={{ justifyContent: 'flex-start' }} hasMarginBottom>
          <Button as={Link} to="/" variant="primary">
            <MdChevronLeft />
            Retour
          </Button>
        </Buttons>
        {actualities.map(({ data }, index) => (
          <ArticleCard actuality={data} key={index} index={index} />
        ))}
        <Buttons>
          {currentPage >= 2 && (
            <Button app="pro" onClick={() => setCurrentPage(currentPage - 1)}>
              Précédent
            </Button>
          )}
          {currentPage !== totalPage && (
            <Button app="pro" onClick={() => setCurrentPage(currentPage + 1)}>
              Suivant
            </Button>
          )}
        </Buttons>
      </Container>
    </Layout>
  );
};

export default ActualitiesList;
